import React, { useState } from 'react'
import avenicaLogo from '../assets/avenica.png'
import { useNavigate, Link } from 'react-router-dom';
import { UserAuth } from './AuthContext';
const Login = () => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const { signIn } = UserAuth();

  const isFormValid = email !== '' && password !== ''

  const navigate = useNavigate();

  const handleSignIn = async (e) => {
    e.preventDefault()
    try {
      await signIn(email, password)
      navigate('/home');
    } catch (e) {
      setErrorMsg(e.message)
      console.log('failed sign in')
    }
  }
  
  return (
    
    <div className='relative w-full h-screen bg-gray-100'>
      <div className='flex justify-center items-center h-full'>
        <form className='max-w-[400px] w-full mx-auto p-2 rounded-lg'>
          <img className='w-[250px] bg-black/90 px-5 mb-8 rounded-lg items-center mx-auto py-4' src={avenicaLogo} alt=''></img>
          <div className='flex flex-col mb-4 px-8'>
            <label className='block mb-2 text-base font-bold text-black'>Email</label>
            <input type='email' value={email} onChange={(e) => setEmail(e.target.value)} className="rounded-lg text-lg text-black required p-1.5"></input>
          </div>
          <div className='flex flex-col mb-4 px-8'>
            <label className='block mb-2 text-base font-bold text-black'>Password</label>
            <input type='password' value={password} onChange={(e) => setPassword(e.target.value)} className="rounded-lg text-lg text-black p-1.5"></input>
          </div>
          <div className='flex flex-col px-8 justify-center mb-6'>
            <button onClick={handleSignIn} disabled={!isFormValid} className='w-full rounded-lg mt-6 py-2 px-8 text-center bg-black/90 font-bold text-lg text-white hover:bg-black/50'>Sign In</button>
            <Link to='/signup'>
              <button className='mt-4 font-bold text-sm hover:opacity-50'>Create an account</button>
            </Link>
          </div>

          {errorMsg.length > 0 ? (
            <div className='font-medium text-center text-red-600 border-2 border-red-600 p-3'>
              {errorMsg}
            </div>
          ) :
            null
          }

        </form>
      </div>
    </div>
  )
}

export default Login