import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import InfoGather from './components/InfoGather';
import WordSelectionQuestion from './components/WordSelectionQuestion';
import AvatarSelectionQuestion from './components/AvatarSelectionQuestion';
import Summary from './components/Summary'
import BreakPage from './components/BreakPage';
import PrivateRoute from './components/PrivateRoute';
import { AuthContextProvider } from './components/AuthContext'
import StartPage from './components/StartPage';
import Login from './components/Login';
import ScorecardQuestion from './components/ScorecardQuestion'
import ScorecardInfo from './components/ScorecardInfo';
import ScorecardEndScreen from './components/ScorecardEndScreen';
import MyScorecards from './components/MyScorecards';
import ScorecardIntroPage from './components/ScorecardIntroPage';
import ScorecardResponse from './components/ScorecardResponse';
import VideoQuestionsPage from './components/VideoQuestionsPage';
import VideoPage from './components/VideoPage';
import SignUp from './components/SignUp';
import HAALOPage from './components/HAALOPage';
import HAALOSummary from './components/HAALOSummary';

function App() {
  return (
    <div className='App'>
      <AuthContextProvider>
        <Router>
          <Routes>
            <Route path="/" element={<StartPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/scorecard/info/:emp_tok" element={<ScorecardInfo />} />
            <Route path="/scorecard" element={<ScorecardQuestion />} />
            <Route path="/scorecard/done" element={<ScorecardEndScreen />} />
            <Route path="/scorecard/introduction" element={<ScorecardIntroPage />} />
            <Route path="/scorecard/response/:token" element={<ScorecardResponse />} />
            <Route path="/myscorecards" element={<PrivateRoute Component={<MyScorecards />} />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/home" element={<PrivateRoute Component={<Home />} />} />
            <Route path="/info" element={<PrivateRoute Component={<InfoGather />}/>} />
            <Route path="/wordquestion/:0" element={<PrivateRoute Component={<WordSelectionQuestion />} />} />
            <Route path="/keymotivators" element={<PrivateRoute Component={<BreakPage title="Key Motivators" paragraph={"Pick the avatar that best aligns with the person you are hoping to identify!"} nextLink={'/avatarquestion/:0'} backLink={'/wordquestion/:2'} />} />} />
            <Route path="/personalitytypes" element={<PrivateRoute Component={<BreakPage title="Personality Types" paragraph={"Pick the avatar that best aligns with the person you are hoping to identify!"} nextLink={'/avatarquestion/:6'} backLink={'/avatarquestion/:5'} />} />} />
            <Route path="/preferences" element={<PrivateRoute Component={<BreakPage title="Preferences" paragraph={"Pick the avatar that best describes someone who would align with you and your team!"} nextLink={'/avatarquestion/:12'} backLink={'/avatarquestion/:11'} />} />} />
            <Route path="/skilldescriptions" element={<PrivateRoute Component={<BreakPage title="Skill Descriptions" paragraph={"Choose which avatar demonstrates the usage/skill level of what is needed for the position!"} nextLink={'/avatarquestion/:18'} backLink={'/avatarquestion/:17'} />} />} />
            <Route path="/avatarquestion/:0" element={<PrivateRoute Component={<AvatarSelectionQuestion />}/>} />
            <Route path="/summary" element={<PrivateRoute Component={<Summary />}/>} />
            <Route path='/video/form' element={<VideoQuestionsPage />}></Route>
            <Route path='/video' element={<VideoPage />}></Route>
            <Route path='/haalo' element={<HAALOPage />}></Route>
            <Route path='/haalo_summary' element={<HAALOSummary />}></Route>
          </Routes>
        </Router>
      </AuthContextProvider>
    </div>
  );
}

export default App;