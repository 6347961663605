import avatar1 from '../assets/AQ1P1.png'
import avatar2 from '../assets/AQ1P2.png'
import avatar3 from '../assets/AQ1P3.png'
import avatar4 from '../assets/AQ1P4.png'

import avatar5 from '../assets/AQ2P1.png'
import avatar6 from '../assets/AQ2P2.png'
import avatar7 from '../assets/AQ3P2.png'
import avatar8 from '../assets/AQ2P4.png'

import avatar9 from '../assets/AQ3P1.png'
// import avatar10 from '../assets/AQ3P2.png'
import avatar11 from '../assets/AQ3P3.png'
import avatar12 from '../assets/AQ3P4.png'

import avatar13 from '../assets/AQ4P1.png'
import avatar14 from '../assets/AQ4P2.png'
import avatar15 from '../assets/AQ4P3.png'
import avatar16 from '../assets/AQ4P4.png'

import avatar17 from '../assets/AQ5P1.png'
import avatar18 from '../assets/AQ5P2.png'
import avatar19 from '../assets/AQ5P3.png'
import avatar20 from '../assets/AQ5P4.png'

import avatar21 from '../assets/AQ6P1.png'
import avatar22 from '../assets/AQ6P2.png'
import avatar23 from '../assets/AQ6P3.png'
import avatar24 from '../assets/AQ6P4.png'

import avatar25 from '../assets/AQ7P1.png'
import avatar26 from '../assets/AQ7P2.png'
import avatar27 from '../assets/AQ7P3.png'
import avatar28 from '../assets/AQ7P4.png'

import avatar29 from '../assets/AQ8P1.png'
import avatar30 from '../assets/AQ8P2.png'
import avatar31 from '../assets/AQ8P3.png'
import avatar32 from '../assets/AQ8P4.png'

import avatar33 from '../assets/AQ9P1.png'
import avatar34 from '../assets/AQ9P2.png'
import avatar35 from '../assets/AQ9P3.png'
import avatar36 from '../assets/AQ9P4.png'

import avatar37 from '../assets/AQ10P1.png'
import avatar38 from '../assets/AQ10P2.png'
import avatar39 from '../assets/AQ10P3.png'
import avatar40 from '../assets/AQ10P4.png'

import avatar41 from '../assets/AQ11P1.png'
import avatar43 from '../assets/AQ11P2.png'

import avatar44 from '../assets/AQ12P1.png'
import avatar45 from '../assets/AQ12P2.png'

const cardsData = [
    {
      id: 0,
      cards: [
        { image: avatar1, statement: 'Without mission, we are no different than robots', isChecked: false},
        { image: avatar2, statement: 'Mission drives every decision I make', isChecked: false},
        { image: avatar3, statement: 'Missions matter but not as much as culture and team', isChecked: false},
        { image: avatar4, statement: 'My mission is solely monetary', isChecked: false}
      ]
    }, 

    {
      id: 1,
      cards: [
        { image: avatar5, statement: 'The world will know my name', isChecked: false},
        { image: avatar6, statement: "We win or lose together, it's all about team", isChecked: false},
        { image: avatar7, statement: 'Collaboration is great as long as all parties contribute', isChecked: false},
        { image: avatar8, statement: 'Teams only work when people know who is in charge', isChecked: false}
      ]
    },

    {
      id: 2,
      cards: [
        { image: avatar9, statement: 'Great problems are solved through deep thinking', isChecked: false},
        { image: avatar44, statement: 'We will change the very nature of how things work', isChecked: false},
        { image: avatar11, statement: 'Great teams trump great ideas everyday', isChecked: false},
        { image: avatar12, statement: 'All I do is win, win, win. No matter what', isChecked: false}
      ]
    },

    {
      id: 3,
      cards: [
        { image: avatar13, statement: 'Send me all the research and data!', isChecked: false},
        { image: avatar14, statement: 'Try, fail, try, fail, try fail, try, fail, figure it out', isChecked: false},
        { image: avatar15, statement: "Let's pull a team together to figure this out!", isChecked: false},
        { image: avatar16, statement: "I've got this", isChecked: false}
      ]
    },

    {
      id: 4,
      cards: [
        { image: avatar17, statement: 'Highly Proactive', isChecked: false},
        { image: avatar18, statement: 'Somewhat Proactive', isChecked: false},
        { image: avatar19, statement: "Somewhat Reactive", isChecked: false},
        { image: avatar20, statement: "Highly Reactive", isChecked: false}
      ]
    },

    {
      id: 5,
      cards: [
        { image: avatar21, statement: 'Highly Inquisitive', isChecked: false},
        { image: avatar22, statement: 'Somewhat Inquisitive', isChecked: false},
        { image: avatar23, statement: "Somewhat Indifferent", isChecked: false},
        { image: avatar24, statement: "Highly Indifferent", isChecked: false}
      ]
    },

    {
      id: 6,
      cards: [
        { image: avatar25, statement: 'Highly Extroverted', isChecked: false},
        { image: avatar26, statement: 'Somewhat Extroverted', isChecked: false},
        { image: avatar27, statement: "Somewhat Introverted", isChecked: false},
        { image: avatar28, statement: "Highly Introverted", isChecked: false}
      ]
    },
    
    {
      id: 7,
      cards: [
        { image: avatar29, statement: 'Always Needs Direction', isChecked: false},
        { image: avatar30, statement: 'Sometimes Needs Direction', isChecked: false},
        { image: avatar31, statement: "Somewhat Independent", isChecked: false},
        { image: avatar32, statement: "Highly Independent", isChecked: false}
      ]
    },

    {
      id: 8,
      cards: [
        { image: avatar33, statement: 'Highly Pragmatic', isChecked: false},
        { image: avatar34, statement: 'Somewhat Pragmatic', isChecked: false},
        { image: avatar35, statement: "Somewhat Idealistic", isChecked: false},
        { image: avatar36, statement: "Highly Idealistic", isChecked: false}
      ]
    },

    {
      id: 9,
      cards: [
        { image: avatar37, statement: 'Highly Figurative', isChecked: false},
        { image: avatar38, statement: 'Somewhat Figurative', isChecked: false},
        { image: avatar39, statement: "Somewhat Literal", isChecked: false},
        { image: avatar40, statement: "Highly Literal", isChecked: false}
      ]
    },

    {
      id: 10,
      cards: [
        { image: avatar41, statement: 'Highly Assertive', isChecked: false},
        { image: avatar2, statement: 'Somewhat Assertive', isChecked: false},
        { image: avatar43, statement: "Somewhat Passive", isChecked: false},
        { image: avatar3, statement: "Highly Passive", isChecked: false}
      ]
    },

    {
      id: 11,
      cards: [
        { image: avatar4, statement: 'Highly Emotive', isChecked: false},
        { image: avatar7, statement: 'Somewhat Emotive', isChecked: false},
        { image: avatar17, statement: "Somewhat Analytical", isChecked: false},
        { image: avatar16, statement: "Highly Analytical", isChecked: false}
      ]
    },

    {
      id: 12,
      cards: [
        { image: avatar44, statement: 'Loves to read, cook, and spend time with his dog Buster', isChecked: false},
        { image: avatar45, statement: 'Loves traveling, volunteering, and gaming', isChecked: false},
        { image: avatar13, statement: "Avid runner and mountain biker who loves the outdoors", isChecked: false},
        { image: avatar25, statement: "Political activist and social media enthusiast", isChecked: false}
      ]
    },

    {
      id: 13,
      cards: [
        { image: avatar41, statement: 'Save more than you spend, fill the 401k and retire at 50', isChecked: false},
        { image: avatar8, statement: 'Saving is overrated; crypto is the only way to go', isChecked: false},
        { image: avatar26, statement: "Invest in what you can impact, bet big on disruption", isChecked: false},
        { image: avatar35, statement: "Live it up, spend it all, figure it out later", isChecked: false}
      ]
    },

    {
      id: 14,
      cards: [
        { image: avatar23, statement: "Mornings are when I shine; I'll sleep in another life", isChecked: false},
        { image: avatar34, statement: "Mornings are ok as long as I've had my eight hours", isChecked: false},
        { image: avatar39, statement: "Mornings are manageable so long as I'm caffeinated", isChecked: false},
        { image: avatar15, statement: "Mornings are my nemesis, I live for the night", isChecked: false}
      ]
    },

    {
      id: 15,
      cards: [
        { image: avatar30, statement: "A life spent helping is a life worth living", isChecked: false},
        { image: avatar40, statement: "A life spent hustling is a life worth living", isChecked: false},
        { image: avatar13, statement: "A life spent learning is a life worth living", isChecked: false},
        { image: avatar29, statement: "A life spent relaxing is a life worth living", isChecked: false}
      ]
    },

    {
      id: 16,
      cards: [
        { image: avatar27, statement: "In ten years I will be… running this place", isChecked: false},
        { image: avatar24, statement: "In ten years I will be… adding value and providing for my family", isChecked: false},
        { image: avatar28, statement: "In ten years I will be… retired and relaxing", isChecked: false},
        { image: avatar38, statement: "In ten years I will be… TikTok famous", isChecked: false}
      ]
    },

    {
      id: 17,
      cards: [
        { image: avatar37, statement: "Good things come to those who wait", isChecked: false},
        { image: avatar1, statement: "Good things come to those who grind", isChecked: false},
        { image: avatar6, statement: "Good things come to all of us equally", isChecked: false},
        { image: avatar22, statement: "Good things come to dreamers", isChecked: false}
      ]
    },

    {
      id: 18,
      cards: [
        { image: avatar37, statement: "Writing is how I make sure I never speak to anyone", isChecked: false},
        { image: avatar43, statement: "Writing is…Can I just call you instead?", isChecked: false},
        { image: avatar14, statement: "Writing is best when it is beautiful and descriptive", isChecked: false},
        { image: avatar17, statement: "Writing is how I show off my vocabulary", isChecked: false}
      ]
    },

    {
      id: 19,
      cards: [
        { image: avatar19, statement: "Macros and regression are my love language", isChecked: false},
        { image: avatar25, statement: "My V-Lookups have color coordinated pivot tables", isChecked: false},
        { image: avatar38, statement: "Sum, average, graph, sort", isChecked: false},
        { image: avatar34, statement: "My grocery list lives in Excel", isChecked: false}
      ]
    },

    {
      id: 21,
      cards: [
        { image: avatar27, statement: "PowerPoint effects are where my true Spielberg shines", isChecked: false},
        { image: avatar45, statement: "PowerPoint is best with no words, just imagery", isChecked: false},
        { image: avatar9, statement: "PowerPoint is for bullet points and data, that's it", isChecked: false},
        { image: avatar32, statement: "PowerPoint is for fully explaining all things… in detail", isChecked: false}
      ]
    },

    {
      id: 22,
      cards: [
        { image: avatar21, statement: "Data is only ever used to destroy", isChecked: false},
        { image: avatar5, statement: "Data without the story is meaningless", isChecked: false},
        { image: avatar40, statement: "Data only adds value when it can be visualized", isChecked: false},
        { image: avatar35, statement: "Data could someday replace human interaction", isChecked: false}
      ]
    },

    {
      id: 23,
      cards: [
        { image: avatar20, statement: "I live to delight customers and solve problems", isChecked: false},
        { image: avatar30, statement: "Customer service is important but hard to do well", isChecked: false},
        { image: avatar26, statement: "I support the idea that customers are the problem", isChecked: false},
        { image: avatar36, statement: "If customers just did what I told them, they wouldn't have problems", isChecked: false}
      ]
    },

    {
      id: 24,
      cards: [
        { image: avatar20, statement: "I will literally do anything but sales", isChecked: false},
        { image: avatar2, statement: "Sales is discipline and can be mastered with time", isChecked: false},
        { image: avatar31, statement: "I don't have the personality for sales and don't typically enjoy speaking with them", isChecked: false},
        { image: avatar33, statement: "A chance to sell is a chance to influence and grow", isChecked: false}
      ]
    },
  ];

  export default cardsData;
