import React from 'react'
import scorecardData from '../JSON/scorecardData'
import { useNavigate } from 'react-router-dom'

const ScorecardIntroPage = () => {

  const navigate = useNavigate()

  return (
    <div className='h-full flex flex-col bg-gray-100 justify-center'>
      <h1 className='font-bold text-center text-xl p-4'>Below are the competencies you will be scoring the employee on: </h1>
      <div className='p-2 flex flex-col'>
        {scorecardData.map((obj, index) => (
          <div key={index} className='p-2'>
            <h1 className='font-bold'>{obj.category}</h1>
            <p>{obj.description}</p>
            <div className='bg-black/5 rounded-lg w-[100%] mt-3 h-1'></div>
          </div>
        ))}
        <button onClick={ () => navigate('/scorecard')} className='mt-5 mb-5 py-1.5 w-[25%] mx-auto justify-center rounded-lg bg-black font-bold text-white hover:bg-black/60'>Begin Rating</button>
      </div>
    </div>
  )
}

export default ScorecardIntroPage