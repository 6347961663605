import React from 'react'

const SummaryPageGroup = ({ array, title }) => {
  return (
    <div className='mb-1 mr-7'>
      <h1 className='font-bold text-lg'>{title}</h1>
      { array.length === 0 ? <h1 className=' font-light text-gray-600'>None</h1> : (    
       array.slice(0, 6).map((response, index) => (
          <div key={response.id} className="text-red-500 text-sm mt-1">
            <h1 key={index}>{`${response.id + 1}: ${response.selected}`}</h1>
              </div>
              ))
      )}
    </div>
  )
}

export default SummaryPageGroup