import React from 'react'
import avenicaLogo from '../assets/avenica.png'

const ScorecardEndScreen = () => {
  return (
    <div className='h-screen flex flex-col items-center justify-center bg-gray-100'>
      <div className='mt-auto font-bold text-4xl mb-4'>Thank you!</div>
      <div className='font-medium text-lg text-center'>We appreciate your time in completing the scorecard. Your feedback will enable us to better understand and help improve candidate performance.</div>
      <div className="mt-auto mb-10 text-gray-500">
        You may close this window.
      </div>
      <img className='flex p-3 bg-black/90 rounded-lg w-48 mb-8' src={avenicaLogo} alt=''/>
    </div>
  )
}

export default ScorecardEndScreen