import React, { useState } from 'react'
import scorecardData from '../JSON/scorecardData'
import { useNavigate } from 'react-router-dom';
import { getFirestore, query, collection, getDocs, where, doc, updateDoc } from 'firebase/firestore'

const ScorecardQuestion = () => {

  const ratings =
  [
    {
      number: 1,
      key: 'Unacceptable Performance'
    },
    {
      number: 2,
      key: 'Inconsistently Meets Expectations'
    },
    {
      number: 3,
      key: 'Meets Expectations'
    },
    {
      number: 4,
      key: 'Exceeds Expectations'
    },
    {
      number: 5,
      key: 'Far Exceeds Expectations '
    }
  ];

  const [data, setData] = useState(scorecardData)
  const [questionIndex, setQuestionIndex] = useState(0)
  const navigate = useNavigate()

  const scorecard_id = sessionStorage.getItem('scorecard_id');

  const showAlert = () => {
    window.alert('Please choose a rating and enter any additional notes.');
  };

  const updateRating = (r) => {
    const newState = data.map(obj => {
      if (obj.id === questionIndex) {
        return {...obj, rating: r};
      }
      return obj;
    });
    setData(newState);
  };

  const updateNotes = (e) => {
    const newState = data.map(obj => {
      if (obj.id === questionIndex) {
        return {...obj, notes: e.target.value};
      }
      return obj;
    });
    setData(newState);
  };

  const goToPrev = () => {
    if (questionIndex > 0) {
      setQuestionIndex(questionIndex - 1)
    }
  }
  
  const handleSubmit = async () => {
      const db = getFirestore();
      var docID = ''
      try {
        const q = query(collection(db, 'scorecards'), where('id', '==', scorecard_id));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          docID = doc.id;
        });
        const ref = doc(db, 'scorecards', docID);
        await updateDoc(ref, {response: data, completed_on: new Date().toLocaleDateString() + ''})
      }
      catch (error) {
        console.log(error.message);
      }
      sessionStorage.removeItem('scorecard_id')
      navigate('/scorecard/done')
  }

  const goToNext = () => {
    if (data[questionIndex].rating === 0 || data[questionIndex].notes === '') {
      showAlert()
      return
    }
    if (questionIndex !== scorecardData.length - 1) {
      setQuestionIndex(questionIndex + 1)
      return
    }
    else {
      handleSubmit()
    }
  }

  return (
    <div className='h-screen flex flex-col bg-gray-100 justify-center'>
        <div className='flex flex-col items-center'>
          <h1 className='text-gray-500 p-3'>{questionIndex + 1}/{scorecardData.length}</h1>
          <h1 className='font-bold text-2xl'>{scorecardData[questionIndex].category}</h1>
          <p className='p-3 font-medium text-center text-gray-800 w-[50%]'>{scorecardData[questionIndex].description}</p>
          <div className='bg-black/5 rounded-lg w-[75%] mt-3 h-1'></div>
        </div>
        <div className='flex items-center justify-center'>
          {ratings.map ((rating, index) => (
            <div key={index} className='mt-8 p-1 flex flex-col items-center'>
              <div onClick={() => updateRating(rating)} className= {`${data[questionIndex].rating.number === rating.number ? 'bg-gray-400' : '' } w-4 h-4 absolute mx-auto border-2 border-gray-400 rounded-full bg-gray-200`}></div> 
              <p className='mt-5 text-center text-sm px-4 font-bold'>{rating.key}</p>
            </div>
          )      
          )}
        </div>
        <div className='flex flex-col items-center justify-center mt-24'>
          <label className='mb-2 text-base font-semibold text-black'>Notes</label>
          <textarea rows='2' cols='70' value={data[questionIndex].notes} onChange={updateNotes} className='resize-none p-1.5 rounded-lg outline outline-1 border-black text-sm text-black'></textarea>
          <div className='mt-12 flex space-x-4'>
            <button onClick={goToPrev} className={`${questionIndex === 0 ? 'bg-black/25' : 'bg-black' } px-8 py-1.5 rounded-lg font-bold text-white hover:bg-black/60`}>←</button>
            <button onClick={goToNext} className='px-8 py-1.5 rounded-lg bg-black font-bold text-white hover:bg-black/60'>{questionIndex === scorecardData.length - 1 ? 'Submit ': '→'}</button>
          </div>
        </div>
      </div>
  )
}

export default ScorecardQuestion;