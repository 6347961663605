import React, { useState, useEffect } from 'react'
import cardsData from '../JSON/cardsData'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { getFirestore, query, where, collection, addDoc, getDocs, deleteDoc, doc } from 'firebase/firestore'
import { AiOutlineArrowLeft } from 'react-icons/ai'

const AvatarSelectionQuestion = () => {

  var questionId = useParams();
  questionId = (parseInt(questionId[0].slice(1)));
  const interview_id = localStorage.getItem('interview_id');
  const navigate = useNavigate()

  const [currentResponse, setCurrentResponse] = useState({
                                                          type: 'asq',
                                                          id: questionId,
                                                          selected: '',
                                                          interview_id: interview_id
                                                         });
                                                         
  const chooseNextPage = () => {
    switch (questionId) {
      case 5:
        return '/personalitytypes';
      case 11:
        return '/preferences';
      case 17:
        return '/skilldescriptions';
      case cardsData.length - 1: 
        return '/summary';
      default:
        return `/avatarquestion/:${questionId + 1}`
    }
  }

  const handleSelection = (word) => {
    setCurrentResponse({type: 'asq',
                        id: questionId, 
                        selected: word,
                        interview_id: interview_id
                       }
                      )
  }

  useEffect(() => {
    const fetchDocument = async () => {
      const db = getFirestore();
      try {
        const q = query(collection(db, 'responses'), where('id', '==', questionId), where('type', '==', currentResponse.type), where('interview_id', '==', interview_id));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const document = querySnapshot.docs[0].data();
          setCurrentResponse(document);
        }
      }
      catch (error) {
        console.log(error.message);
      }
    }
    fetchDocument()
  }, [questionId, currentResponse.type, interview_id]); 

  const goToPrevQuestion = () => {
    setCurrentResponse({ type: 'asq', id: questionId - 1, selected: '', interview_id: interview_id })
  }

  const goToNextQuestion = async () => {
    if (currentResponse.selected === '') {
      return
    }

    // check if a response exists
    const db = getFirestore();
    try {
      const docRef = query(collection(db, 'responses'), where('id', '==', questionId), where('type', '==', 'asq'), where('interview_id', '==', interview_id));
      const querySnapshot = await getDocs(docRef);
      if (!querySnapshot.empty) {
        const docRef = doc(db, 'responses', querySnapshot.docs[0].id);
        await deleteDoc(docRef);
      }
    } catch (error) {
      console.log(error)
    }
    try {
      await addDoc(collection(db, 'responses'), currentResponse)
    }
    catch (error) {
      console.log(error);
    }
    finally {
      setCurrentResponse({ type: 'asq', id: questionId, selected: '', interview_id: interview_id })
      navigate(chooseNextPage())
    }
  }

  return (
    <div className='h-screen bg-gray-100'>
      <div className='flex w-[8%] items-center space-x-2 p-4 hover:opacity-50'>
            <AiOutlineArrowLeft />
            <Link to='/home'>
              <h1 className='m-0 font-semibold'>Home</h1>  
            </Link>
        </div>
      <div className='flex flex-col justify-center'>
        <div className='flex flex-row justify-center items-center relative'>
          {cardsData[questionId].cards.map ((card, index) => (
            <div key={index} onClick={() => handleSelection(card.statement)} className={`${card.statement === currentResponse.selected ? 'outline outline-1.5 outline-sky-500' : ' ' } rounded-lg w-[15%] flex flex-col bg-gray-200 h-96 m-8`}>
              <h1 className='p-2 text-gray-600 text-lg mx-auto font-semibold'>{index + 1}</h1>
              <img className='max-w-[70%] mx-auto pt-4' src={card.image} alt=''></img>
              <p className='mt-5 w-full text-center text-sm px-4 font-bold'>{card.statement}</p>
              {/* <div onClick={() => handleSelection(card.statement)} className={`${card.statement === currentResponse.selected ? 'bg-gray-400' : '' } w-4 h-4 absolute mx-auto border-2 border-gray-400 rounded-full bg-gray-200`}></div>  */}
            </div>
          )      
          )}
        </div>
        <div className='flex items-center gap-x-8 justify-center'>
          <Link to={ questionId === 0 ? '/keymotivators' : `/avatarquestion/:${questionId - 1}`}>
            <button onClick={goToPrevQuestion} className='flex ml-24 mt-10 px-6 py-2 rounded-lg bg-black font-bold text-white hover:bg-black/60'>←</button>
          </Link>
            <button onClick={goToNextQuestion} className='flex mr-24 mt-10 px-6 py-2 rounded-lg bg-black font-bold text-white hover:bg-black/60'>→</button>
        </div>
        <div className='flex justify-end'>
            <h1 className='font-bold p-10'>{questionId + 1}</h1>
          </div>
      </div>
    </div>
  )
}

export default AvatarSelectionQuestion