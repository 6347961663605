import { React,  } from 'react'

const VideoQuestionsPage = () => {
      
  const script = document.createElement('script');
  script.src = 'https://sfapi.formstack.io/FormEngine/Scripts/Main.js?d=RNjGX1HWh8BJkD1rnyenxMNOFnTHyQxHr0yXkYVO2ictYUPdLfGiRrZNtzOi3DDM';
  script.type = 'text/javascript';      
  document.head.appendChild(script);

  return (
    <div className='bg-gray-100'>
      <script crossOrigin='anonymous' id="jsFastForms" src="https://sfapi.formstack.io/FormEngine/Scripts/Main.js?d=RNjGX1HWh8BJkD1rnyenxMNOFnTHyQxHr0yXkYVO2ictYUPdLfGiRrZNtzOi3DDM">Form</script>
    </div>
  )
}

export default VideoQuestionsPage