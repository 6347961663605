import { React, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import video from '../assets/tmay-video.mp4'
import Popup from 'reactjs-popup';
import { FaPause, FaPlay } from 'react-icons/fa'

const VideoPage = () => {
    const [videoPlaying, setVideoPlaying] = useState(false)
    const [popUpShow, setPopUpShow] = useState(true)

    const videoRef = useRef(null);

    const navigate = useNavigate()

    const goToVideoForm = () => {
        navigate('/video/form')
    }

    const playVideo = () => { 
        const video = videoRef.current;        
        video.play();
        setVideoPlaying(true)
    }

    const pauseVideo = () => {
        const video = videoRef.current;        
        video.pause();
        setVideoPlaying(false)
    }

  return (
    <div className='bg-gray-200 h-screen w-[100%] flex-col flex items-center justify-center'>
            <div className=''>
                {popUpShow ? 
                    <Popup defaultOpen closeOnDocumentClick={false} position="center">
                        <div className='flex flex-col items-center shadow-lg leading-5 p-8 bg-gray-200 outline outline-black rounded-lg'>
                            <h1 className='w-70 sm:w-60 lg:w-96 font-bold'>Interview Question Spotlight Video Instructions</h1>
                            <p className='mt-2 w-70 sm:w-60 lg:w-96'>Welcome to the next step of Avenica's process! The purpose of this Spotlight Video is to guide you in addressing the frequently asked, "tell me about yourself" interview question. Please watch this video in its entirety and keep in mind that you won't have the option to fast-forward. Following the video, we'll ask you to provide a brief written response, sharing your takeaways. We are excited to review your response!</p>
                            <button onClick={() => setPopUpShow(false)} className='mt-5 font-bold'>Next →</button>
                        </div>
                    </Popup>
                    : null 
                }
                <video ref={videoRef} onEnded={goToVideoForm} controls={false} playsInline className={`${videoPlaying ? null : 'blur-sm'} rounded-lg`} width="850" height="600">
                    <source src={video} type="video/mp4" />
                    Sorry, your browser doesn't support videos.
                </video>
                {!videoPlaying ? 
                <div onClick={playVideo} className='flex flex-row cursor-pointer items-center p-2'>
                    <FaPlay className='mr-2 text-gray-800' />
                    <h1 className='font-semibold text-gray-800'>Play video</h1>
                </div>
                :
                <div onClick={pauseVideo} className='flex flex-row cursor-pointer items-center p-2'>
                    <FaPause className='mr-2 text-gray-800' />
                    <h1 className='font-semibold text-gray-800'>Pause video</h1>
                </div>
                }
            </div>
            <h1 className={`${videoPlaying ? 'text-gray-500 mt-4 w-[300px] text-center lg:w-fit' : 'mt-4 text-gray-200'}`}>You will be automatically redirected when the video is over.</h1>
    </div>
  )
}

export default VideoPage