import React, { useState } from 'react'
import avenicaLogo from '../assets/avenica.png'
import { useNavigate, Link } from 'react-router-dom';
import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth';
import { getFirestore, collection, addDoc} from 'firebase/firestore'

const SignUp = () => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  const isFormValid = email !== '' && password !== ''

  const auth = getAuth();
  const navigate = useNavigate();

  const handleSignUp = async (e) => {
    e.preventDefault()
    // register user
    await createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // add to user collection
      const db = getFirestore();
      try {
        addDoc(collection(db, 'users'), {id: userCredential.user.uid, 
                                         email: email, 
                                         role: 'external',
                                         })
      }
      catch (error) {
        console.log(error);
      }

      navigate('/home');

    }).catch((error) => setErrorMsg(error.message))    
  }
  
  return (
    <div className='relative w-full h-screen bg-gray-100'>
      <div className='flex justify-center items-center h-full'>
        <form className='max-w-[400px] w-full mx-auto p-2 rounded-lg'>
          <img className='w-[250px] bg-black/90 px-5 mb-8 rounded-lg items-center mx-auto py-4' src={avenicaLogo} alt=''></img>
          <div className='flex flex-col mb-4 px-8'>
            <label className='block mb-2 text-base font-bold text-black'>Email</label>
            <input type='email' value={email} onChange={(e) => setEmail(e.target.value)} className="rounded-lg text-lg text-black required p-1.5"></input>
          </div>
          <div className='flex flex-col mb-4 px-8'>
            <label className='block mb-2 text-base font-bold text-black'>Password</label>
            <input type='password' value={password} onChange={(e) => setPassword(e.target.value)} className="rounded-lg text-lg text-black p-1.5"></input>
          </div>
          <div className='px-8 mb-6'>
            <button onClick={handleSignUp} disabled={!isFormValid} className='w-full rounded-lg mt-6 py-2 px-8 text-center bg-black/90 font-bold text-lg text-white hover:bg-black/50'>Sign Up</button>
            <Link to='/login'>
              <button className='mt-4 font-bold text-sm hover:opacity-50'>Already have an account?</button>
            </Link>
          </div>
          

          {errorMsg.length > 0 ? (
            <div className='font-medium text-center text-red-600 underline'>
              {errorMsg.slice(9, errorMsg.length - 1)}
            </div>
          ) :
            null
          }
        </form>
      </div>
    </div>
  )
}

export default SignUp