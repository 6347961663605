import React, { useState, useEffect } from 'react'
import avenicaLogo from '../assets/avenica.png'
import { Link, useNavigate } from 'react-router-dom'
import { getFirestore, collection, addDoc, getDoc, doc} from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { AiOutlineArrowLeft } from 'react-icons/ai'

const InfoGather = () => {

  const [currentValues, setCurrentValues] = useState({companyName: '', contactName: '', position: ''})

  const [interview_id, setInterviewId] = useState('')

  const isFormValid = (currentValues.companyName !== '' && currentValues.contactName !== '' && currentValues.position !== '')

  const navigate = useNavigate()

  const auth = getAuth()

  const handleChange = (e) => {
    setCurrentValues({
      ...currentValues,
      [e.target.id]: e.target.value
    });
  }

  useEffect(() => {
     try { 
      setInterviewId(localStorage.getItem('interview_id'))
    } catch (e) {
      return
    }

    const fetchDocument = async () => {
      const db = getFirestore();
      try {
        const docRef = doc(db, 'interviews', interview_id)
        const docSnap = await getDoc(docRef);
        if (!docSnap.empty) {
          const document = docSnap.data()
          setCurrentValues({companyName: document.name, contactName: document.title, position: document.position});
        }
      }
      catch (error) {
        console.log(error.message);
      }
    }
    fetchDocument();
  }, [interview_id]); 

  const saveInfoToFB = async (e) => {
    e.preventDefault();
    if (localStorage.getItem('interview_id')) {
      navigate('/wordquestion/:0');
      return  
    }
    const db = getFirestore();
    
    try {
      await addDoc(collection(db, 'interviews'), {user_id: auth.currentUser.uid, 
                                            companyName: currentValues.companyName, 
                                            contactName: currentValues.contactName, 
                                            position: currentValues.position,
                                            assessment_id: 1,
                                          }
                  )
                  .then((doc) => {
                    localStorage.setItem('interview_id', doc.id)
                  })
                }

    catch (error) {
    }

    navigate('/wordquestion/:0');
  }

  return (
    <div className='h-screen bg-gray-100'>
      <div className='flex w-[8%] items-center space-x-2 p-4 hover:opacity-50'>
            <AiOutlineArrowLeft />
            <Link to='/home'>
              <h1 className='font-semibold'>Home</h1>  
            </Link>
        </div>
      <div className='flex h-[85%] items-center justify-center bg-gray-100'>
        <form className='max-w-[400px] w-full mx-auto p-2 rounded-lg'>
          <div className='flex flex-col mb-4 px-8'>
            <label className='block mb-2 text-base font-bold text-black'>Company Name</label>
            <input type='text' id='companyName' value={currentValues.companyName} onChange={handleChange} className="rounded-lg border-black text-lg text-black p-1.5" ></input>
          </div>
          <div className='flex flex-col mb-4 px-8'>
            <label className='block mb-2 text-base font-bold text-black'>Contact Name</label>
            <input type='text' id='contactName' value={currentValues.contactName} onChange={handleChange} className="rounded-lg border-black text-lg text-black p-1.5"></input>
          </div>
          <div className='flex flex-col mb-4 px-8'>
            <label className='block mb-2 text-base font-bold text-black'>Position Hiring For</label>
            <input type='text' id='position' value={currentValues.position} onChange={handleChange} className="rounded-lg border-black text-lg text-black p-1.5"></input>
          </div>
          <div className='px-8 mb-6'>
            <button disabled={!isFormValid} onClick={saveInfoToFB} className='w-[35%] rounded-lg mt-4 py-2 text-center bg-black/90 font-bold text-white text-lg hover:bg-black/60'>Next</button>
          </div>
      </form>
      <img className='flex mx-auto p-3 bg-black/90 rounded-lg w-60 h-fit' src={avenicaLogo} alt=''></img>
      </div>
    </div>
  )
}

export default InfoGather