import React from 'react'
import { UserAuth } from './AuthContext';
import { Navigate } from 'react-router-dom';

const StartPage = () => {

  const { user } = UserAuth();
  
  if (user) {
    return <Navigate to='/home' />
  }

  else {
    return <Navigate to='/login' />
  }

}

export default StartPage