import { Navigate } from 'react-router-dom';
import { UserAuth } from './AuthContext';

const PrivateRoute = ({ Component }) => {
  const { user } = UserAuth();

  if (!user) {
    return <Navigate to="/login" />
    
  }
  
  return Component
}

export default PrivateRoute