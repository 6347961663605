import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import haaloData from '../JSON/haaloData'
import { AiOutlineArrowLeft } from 'react-icons/ai';
import HAALOSummary from './HAALOSummary';

const HAALOPage = () => {

    const [questionIndex, setQuestionIndex] = useState(0);

    const [choices, setChoices] = useState(() => {
        const initialArray = [];
    
        for (let i = 0; i < 30; i++) {
          initialArray.push({questionId: i, optionIndex: -1, option: null})
        }
        return initialArray
    });

    const handleResponseChange = (option, index) => {
        setChoices(prevChoices => {
          const newChoices = [...prevChoices];
          newChoices[questionIndex] = {questionId: questionIndex, optionIndex: index, option: option};
          return newChoices;
        });
    };

    const handleNextQuestion = () => {
        if (questionIndex <= haaloData.length - 1 && choices[questionIndex].option != null) {
            setQuestionIndex(questionIndex + 1)
        }  
    }

    if (questionIndex <= 29) {
        return (
            <div className='h-screen bg-gray-100'>
                <div className='w-[7%] items-center space-x-2 p-4 hover:opacity-50'>
                    <Link to='/home' className='flex flex-row items-center space-x-2'>
                        <AiOutlineArrowLeft />
                        <h1 className='m-0 font-semibold'>Home</h1>  
                    </Link>
                </div>

                <div className='h-20'></div> {/* spacer */} 

                <div className='flex justify-center mb-8'>
                    <h1 className='font-normal text-gray-500'>{questionIndex+1}/{haaloData.length}</h1>
                </div>
                
                <div className='flex flex-col items-center justify-center'>
                    <h1 className='font-semibold text-gray-500'>Pick the option that best describes you.</h1>
                </div>
                
                <div className='flex h-[30%] flex-col mt-10 items-center justify-center'>
                    {haaloData[questionIndex].options.map((option, index) => (
                        <div key={index} className='w-[32%]' onClick={() => handleResponseChange(option, index)}>
                            <h1 className={`p-4 cursor-pointer font-bold mx-auto text-black bg-gray-300 rounded-lg mb-8 
                            ${choices[questionIndex].optionIndex === index ? 'outline outline-2' : ''}`} alt=''>{option.statement}</h1>
                        </div>
                    )  
                )}
                </div>

                <div className='flex top-0 items-center gap-x-8 justify-center'>
                    <button onClick={questionIndex !== 0 ? () => setQuestionIndex(questionIndex-1) : null} className={`${questionIndex === 0 ? 'opacity-30' : 'hover:bg-black/60'} 'flex ml-24 mt-10 px-6 py-2 rounded-lg bg-black font-bold text-white`}>←</button>
                    <button onClick={handleNextQuestion} className='flex mr-24 mt-10 px-6 py-2 rounded-lg bg-black font-bold text-white hover:bg-black/60'>→</button>
                </div>
                
            </div>
        )
    } 
    else {
        return <HAALOSummary responses={choices} />
    }
}

export default HAALOPage