const wordsData = [
    {
      id: 0,
      question: "What three words best describe their values?",
      words: ['Humble', 'Resilient', 'Innovative', 'Loyal', 'Compassionate', 
      'Curious', 'Creative', 'Purpose', 'People-first', 'Accountable',
     'Initiative', 'Team-oriented'],
      selections: []
    },
    { 
      id: 1,
      question: "What three choices best describe the team's recreational interests (how do they relax/connect)?",
      words: ['Team kickball game', 'Quick wrap up the day chat', 'Group lunchtime', 'Holiday theme parties',
              'After work happy hour', 'A quick connect calendar invite', 'Virtual book club', 'A sports game outing',
              'Scheduled quarterly get together', 'A walk to catch up', 'Daily stand up/coffee', 'Team group chat'],
      selections: []
    },
    { 
      id: 2,
      question: "What three phrases best summarize the team's overall work style?",
      words: ['Quick, responsive and attentive', 'Reports, dashboard and metrics', "Collaborative, everyone's input is important", 
              'Free thinking and creative', 'Work hard, play hard', 'Adaptable, able to do to what needs to be done', 'Independent, we know the job and do it well',
              "Solution-oriented, we don't spend time on what we can't do", 'Specific steps on what needs to be done and are always followed', 'Supportive, no question is a bad one',
              'Everything is a competition', 'Customer/Client first'],
      selections: []
    }
  ];

  export default wordsData;