import React, { useEffect, useState } from 'react'
import * as PersonaService from '../usefulFunctions/PersonaService'
import { Link } from 'react-router-dom';
import { AiOutlineArrowLeft } from 'react-icons/ai';

const HAALOSummary = ({ responses }) => {

    const [persona, setPersona] = useState({})
    const percentages = PersonaService.getPercentages(responses)
    
    useEffect(() => {
        setPersona(PersonaService.findPersona(responses))
    }, [responses])

  return (
    <div className='h-screen bg-gray-100'>

        <div className='w-[7%] items-center space-x-2 p-4 hover:opacity-50'>
            <Link to='/home' className='flex flex-row items-center space-x-2'>
                <AiOutlineArrowLeft />
                <h1 className='m-0 font-semibold'>Home</h1>  
            </Link>
        </div>

        <div className='h-12'></div> {/* spacer */} 

        <div className='flex flex-row items-center justify-center space-x-12'>
            <div className='flex-col'>
                <div className='flex flex-col items-center justify-center'>
                    <div className='text-gray-400'>Your Persona</div>
                    <div className='font-bold text-black text-4xl'>The {persona.name}</div>
                </div>
                
                <div className='h-10'></div> {/* spacer */} 
                
                <div className='flex flex-col items-center justify-center'>

                    <div className='bg-gray-200 rounded-md p-3 items-center justify-center'>
                        {persona.description?.map((desc, index) => (
                            <div key={index} className='p-2 font-semibold text-sm'>
                                <div className='text-black mb-2'>• {desc}</div>
                                {index === persona.description.length - 1 ? null : <div className='bg-black/5 w-[100%] h-0.5 rounded-lg'></div>}
                            </div>
                        ))} 
                    </div>
                </div>
            </div>

            <div className='items-center justify-center grid grid-cols-2 gap-4 mt-16'>
                <ResultCircle letter={'A'} percent={percentages['A']} color={'bg-red-500'} />
                <ResultCircle letter={'H'} percent={percentages['H']} color={'bg-orange-500'} />
                <ResultCircle letter={'C'} percent={percentages['C']} color={'bg-blue-500'} />
                <ResultCircle letter={'M'} percent={percentages['M']} color={'bg-green-500'} />
            </div>
        </div>
    </div>
    
  )
}

export const ResultCircle = ({letter, percent, color}) => {
    return (
    <div className={`flex w-28 h-28 text-white font-bold text-lg justify-center items-center rounded-full shadow-black/30 shadow-sm ${color}`}>
        <div className=''>
            <h1 className='flex justify-center'>{letter}</h1>
            <h1 className='flex justify-center'>{Math.round(percent * 100)}%</h1>
        </div>
    </div>
    )
}

export default HAALOSummary