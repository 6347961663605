import '../App.css';
import { React, useEffect, useState } from 'react';
import avenicaLogo from '../assets/avenica.png'
import { Link, useNavigate } from 'react-router-dom';
import { getFirestore, query, where, collection, getDocs, orderBy, limit} from 'firebase/firestore'
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { UserAuth } from './AuthContext';

function Home() {

  const [options, setOptions] = useState([])
  const [selected, setSelected] = useState([])
  const { user, logout } = UserAuth();
  const navigate = useNavigate()

  const handleChange = (e) => {
    setSelected(e.value)
  }

  const setActiveInterviewId = async (summary) => {
    let db = getFirestore()
    try {
      // Get selected interview
      const interviewsQuery = query(collection(db, 'interviews'), (where('contactName', '==', selected[2])), where('user_id', '==', user.uid))
      const interviewQuerySnapshot = await getDocs(interviewsQuery)
      if (interviewQuerySnapshot.empty) {
        console.log('found nothing')
        return null;
      }
      const interview_id = interviewQuerySnapshot.docs[0].id;
      localStorage.setItem('interview_id', interview_id)

      // Get responses for interview
      const responsesQuery = query(collection(db, 'responses'), where('interview_id', '==', interview_id), orderBy('type', 'asc'), orderBy('id', 'desc'), limit(1))
      const responsesQuerySnapshot = await getDocs(responsesQuery)
      if (responsesQuerySnapshot.empty) {
        return null;
      }

      if (summary) {
        navigate('/summary')
        return
      } else {
        navigate('/wordquestion/:0');
      }
      
      // Find highest index response
      // const highestIndexResponseDoc = responsesQuerySnapshot.docs[0].data();
      // if (highestIndexResponseDoc.type === 'wsq') {

      //   navigate(`/wordquestion/:${highestIndexResponseDoc.id}`)
      // } 
      // else if (highestIndexResponseDoc.id === cardsData.length - 1) {
      //   navigate('/summary')
      // }
      // else {
      //   navigate(`/avatarquestion/:${highestIndexResponseDoc.id}`)
      // }

    } catch (error) {
        console.log(error.message)
    } 
  }

  const handleSignOut = async (e) => {
    try {
      await logout()
      navigate('/login')
    } catch (e) {
      console.log(e.message);
    }
  }

  const handleInitiateLaunch = () => {
    localStorage.removeItem('interview_id'); 
    navigate('/info');
  }

  useEffect(() => {
    const getInterviews = async () => {
      const db = getFirestore();
      try {
        const q = query(collection(db, 'interviews'), where('user_id', '==', user.uid));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const documents = querySnapshot.docs
          let pastInterviews = []
          for (let i = 0; i < documents.length; i++) {
            let doc = documents[i].data()
            pastInterviews.push([doc.companyName, ', ', doc.contactName, ', ', doc.position])
          }
          setOptions(pastInterviews.sort())
        }
      }
      catch (error) {
        console.log(error.message);
      }
    }
    getInterviews();
  }, [user]); 

  return (
      <div className='flex flex-col h-screen items-center bg-gray-100'>
        <div className='flex flex-col items-center mt-20'>
          <img className='w-[400px] bg-black/90 px-5 rounded-lg items-center mx-auto py-4' src={avenicaLogo} alt='Avenica Logo'></img>
          <h1 className='font-bold text-black/90 text-2xl p-6 mb-2'>Unleash Human Potential</h1>
          <FloatingButton onCLCK={handleInitiateLaunch} />
        </div>
        <div className='flex flex-col items-center justify-center'>
          <h1 className='font-regular text-xl p-5 text-gray-500 mt-16'>Past Implementations</h1>
            <Dropdown className='w-[150%]' onChange={handleChange} options={options} placeholder={'Select...'}></Dropdown>
            
            {selected.length > 0 ? 
              <div className='flex items-center justify-center space-x-4'>
                <h1 onClick={() => setActiveInterviewId(false)} className='bg-black/80 text-white font-semibold rounded-md px-2 py-1 mt-4 cursor-pointer hover:bg-black/70'>Edit</h1>
                <h1 onClick={() => setActiveInterviewId(true)} className='bg-black/80 text-white font-semibold rounded-md px-2 py-1 mt-4 cursor-pointer hover:bg-black/70 '>View Summary</h1>
              </div>
                : null
            }
        </div>
        <div className='absolute w-[100%] h-[7%] bg-black text-white font-bold p-3'>
          <Link to='/'>
            <button className='bg-black px-2 py-1 text-sm rounded-md hover:bg-black/70' onClick={handleSignOut}>Sign Out</button>
          </Link>
          <Link to='/myscorecards'>
            <button className='bg-black px-2 py-1 ml-4 text-sm rounded-md hover:bg-black/70'>Scorecards</button>
          </Link>
          <Link to='/haalo'>
            <button className='bg-black px-2 py-1 ml-4 text-sm rounded-md hover:bg-black/70'>HAALO</button>
          </Link>
          {/* {user.role === 'internal' ?
          <Link to='/myscorecards'>
            <button className='bg-black px-2 py-1 ml-4 text-sm rounded-md hover:bg-black/70'>Scorecards</button>
          </Link>
          : null
          } */}
        </div>
      </div>
  );
}

const FloatingButton = ( { onCLCK }) => (
  <button onClick={onCLCK} className='bg-black/90 hover:bg-black/80 px-10 py-4 border-2 rounded-lg font-bold text-white border-cyan-400'>Initiate Launch</button>
);

export default Home;