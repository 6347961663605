const scorecardData = [
    {
      id: 0,
      category: "Punctuality",
      description: 'Consistently arrives at work and meetings on time, demonstrating reliability and respect for schedules. Replies to messages and requests in an appropriate amount of time.',
      rating: 0,
      notes: ''
    },
    {
      id: 1,
      category: "Communication",
      description: 'Proficiently conveys ideas and information clearly, concisely, and timely to internal and external parties, both verbal and written.',
      rating: 0,
      notes: ''
    },
    {
      id: 2,
      category: "Professionalism",
      description: "Demonstrates reliability and takes ownership of one's responsibilities/duties. Exhibits a high degree of dedication and attention to one's work, maintains a positive attitude, and conducts oneself with integrity. Fosters trust and respect among peers, clients and/or customers.",
      rating: 0,
      notes: ''
    },
    {
      id: 3,
      category: "Collaboration",
      description: "Shows interest in working with colleagues in pursuit of a shared, collective, common goal, to create or achieve a task.",
      rating: 0,
      notes: ''
    },
    {
      id: 4,
      category: "Willingness to Learn",
      description: "Desire to gain knowledge and develop skills to improve work performance. Positively engaged, exhibits patience and commitment to continuously acquiring knowledge to develop oneself in the position and organization.",
      rating: 0,
      notes: ''
    },
    {
      id: 5,
      category: "Initiative",
      description: "Self-starter with the proven ability to successfully complete daily responsibilities without consistent reminders. Demonstrates critical thinking skills by problem-solving, utilizing resources to resolve issues, and seeking support as needed.",
      rating: 0,
      notes: ''
    },
    {
      id: 6,
      category: "Technical Skills",
      description: "Comprehends the skills and tools to effectively handle the technical demands and tasks of their job. This could include methods of communication, software programs, databases, etc.",
      rating: 0,
      notes: ''
    },
    {
      id: 7,
      category: "Quality of Work Performed",
      description: "Quality of work requirements for the role are met in everyday duties. Continues to improve in areas including but not limited to, following procedures, fulfilling all deadlines, satisfying individual and team objectives, and maintaining accuracy.",
      rating: 0,
      notes: ''
    },
    {
      id: 8,
      category: "Quantity of Work Performed",
      description: "Demonstrates a strong commitment to meeting deadlines and metric goals. Has a high level of productivity, consistently completes tasks in a timely manner and has a proven ability to balance multiple tasks and priorities effectively.",
      rating: 0,
      notes: ''
    },
    {
      id: 9,
      category: "Organization Alignment",
      description: "Aligns with company values, practices, and behaviors. Shown the ability and interest in building positive professional relationships with team members, interested in their development and the organization's goals.",
      rating: 0,
      notes: ''
    },
  ];

  export default scorecardData;