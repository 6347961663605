import {React, useState } from 'react'
import { useParams } from 'react-router-dom'
import avenicaLogo from '../assets/avenica.png'
import { useNavigate } from 'react-router-dom'
import { getFirestore, collection, addDoc, updateDoc } from 'firebase/firestore'

const ScorecardInfo = () => {
  var urlToken = useParams()['emp_tok'];

  const [currentValues, setCurrentValues] = useState({company: '', manager: '', employee: ''})

  const isFormValid = (currentValues.company !== '' && currentValues.manager !== '' && currentValues.employee !== '')

  const handleChange = (e) => {
    setCurrentValues({
      ...currentValues,
      [e.target.id]: e.target.value
    });
  }

  const navigate = useNavigate();

  const createScorecardResponse = async () => {
    const db = getFirestore();

    const d = new Date()
    d.setMonth(d.getMonth() + 1)
    
    try {
      await addDoc(collection(db, 'scorecards'), {
                                            company: currentValues.company,
                                            manager: currentValues.manager,
                                            employee: currentValues.employee,
                                            scorecard_token: urlToken, 
                                            completed_on: '', 
                                            response: '',
                                          }
                  ).then( async (doc) => {
                    await updateDoc(doc, {id: doc.id});
                    sessionStorage.setItem('scorecard_id', doc.id)
                  })
    }

    catch (error) {
      console.log(error.message)
    }

    navigate('/scorecard/introduction')
  }

  // const updateScorecardInfo = async () => {
  //   const db = getFirestore();
  //   let docID = ''
  //   try {
  //     const q = query(collection(db, 'invitations'), where('token', '==', sessionStorage.getItem('token')));
  //     const querySnapshot = await getDocs(q);
  //     querySnapshot.forEach((doc) => {
  //       docID = doc.id;
  //     });
  //     const ref = doc(db, 'invitations', docID);
  //     await updateDoc(ref, {company: currentValues.company, employee: currentValues.employee, manager: currentValues.manager})
  //   }
  //   catch (error) {
  //     console.log(error.message);
  //   }
  //   navigate(`/scorecard/introduction/${urlToken}`)
  // }

  return (
    <div className='h-screen bg-gray-100'>
      <div className='flex h-screen items-center justify-center bg-gray-100'>
        <form className='max-w-[400px] w-full mx-auto p-2 rounded-lg'>
          <div className='flex flex-col mb-4 px-8'>
            <label className='block mb-2 text-base font-bold text-black'>Company</label>
            <input type='text' id='company' value={currentValues.company} onChange={handleChange} className="rounded-lg border-black text-lg text-black p-1.5"></input>
          </div>
          <div className='flex flex-col mb-4 px-8'>
            <label className='block mb-2 text-base font-bold text-black'>Manager</label>
            <input type='text' id='manager' value={currentValues.manager} onChange={handleChange} className="rounded-lg border-black text-lg text-black p-1.5"></input>
          </div>
          <div className='flex flex-col mb-4 px-8'>
            <label className='block mb-2 text-base font-bold text-black'>Employee</label>
            <input type='text' id='employee' value={currentValues.employee} onChange={handleChange} className="rounded-lg border-black text-lg text-black p-1.5"></input>
          </div>
          <div className='px-8 mb-6'>
            <button type='button' disabled={!isFormValid} onClick={createScorecardResponse} className='w-[35%] rounded-lg mt-4 py-2 text-center bg-black/90 font-bold text-white text-lg hover:bg-black/60'>Next</button>
          </div>
      </form>
      <img className='flex mx-auto p-3 bg-black/90 rounded-lg w-62 h-14' src={avenicaLogo} alt=''></img>
      </div>
    </div>
  )
}

export default ScorecardInfo