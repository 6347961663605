import { createContext, useContext, useEffect, useState } from 'react';
import { onAuthStateChanged, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { getFirestore, query, collection, getDocs, where } from 'firebase/firestore';
import { auth } from '../firebase';

const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState({});

  const logout = () => {
    try {
      signOut(auth)
    } catch (e) {
      console.log(e.message)
    }
  }

  const signIn = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password)
  }
  
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      console.log(currentUser);
      setUser(currentUser);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  // useEffect(() => {
  //   const setUpUser = onAuthStateChanged(auth, async (currentUser) => {
  //     setUser(currentUser);
  //   //   if (currentUser) {
  //   //     const db = getFirestore();
  //   //     try {
  //   //       const q = query(collection(db, 'users'), where('id', '==', currentUser.uid));
  //   //       const querySnapshot = await getDocs(q);
  //   //       if (!querySnapshot.empty) {
  //   //         const userDoc = querySnapshot.docs[0].data()
  //   //         setUser({
  //   //           uid: currentUser.uid,
  //   //           email: currentUser.email,
  //   //           role: userDoc.role,
  //   //         })
  //   //       }
  //   //     }
  //   //     catch (error) {
  //   //       console.log(error.message)
  //   //       setUser({})
  //   //     }
  //   //   }
  //   // });
  //   return () => {
  //     setUpUser();
  //   };
  //   })
  // }, []);

  return (
    <UserContext.Provider value={{ logout, signIn, user }}>
      {children}
    </UserContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(UserContext);
};