import React, { useEffect, useState } from 'react'
import wordsData from '../JSON/wordsData'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getFirestore, query, where, collection, addDoc, getDocs, deleteDoc, doc } from 'firebase/firestore'
import { AiOutlineArrowLeft } from 'react-icons/ai'

const WordSelectionQuestion = () => {

  var questionId = useParams();
  questionId = (parseInt(questionId[0][1]));
  const interview_id = localStorage.getItem('interview_id');
  const navigate = useNavigate()

  const [currentResponse, setCurrentResponse] = useState( { type: 'wsq',
                                                            id: questionId, 
                                                            selected: [],
                                                            interview_id: interview_id
                                                          } );

  const handleSelection = (word) => {
    if (currentResponse.selected.includes(word)) {
      setCurrentResponse({type: 'wsq',
                          id: questionId + 1, 
                          selected: currentResponse.selected.filter(item => item !== word),
                          interview_id: interview_id
                         }
                        )
    }
    else if (currentResponse.selected.length !== 3) {
      setCurrentResponse({ type: 'wsq', 
                          id: questionId, 
                          selected: [...currentResponse.selected, word], 
                          interview_id: interview_id});
    }
  }

  useEffect(() => {
    const fetchDocument = async () => {
      const db = getFirestore();
      try {
        const q = query(collection(db, 'responses'), where('id', '==', questionId), where('type', '==', currentResponse.type), where('interview_id', '==', interview_id));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const document = querySnapshot.docs[0].data();
          setCurrentResponse(document);
          console.log(document)
        }
      }
      catch (error) {
        console.log(error.message);
      }
    }
    fetchDocument();
  }, [questionId, currentResponse.type, interview_id]);

  const goToPrevQuestion = () => {
    setCurrentResponse({ type: 'wsq', id: questionId - 1, selected: [], interview_id: interview_id })
  }

  const goToNextQuestion = async () => {
    if (currentResponse.selected.length !== 3) {
      return
    }

    // check if a response exists
    const db = getFirestore();
    try {
      const docRef = query(collection(db, 'responses'), where('id', '==', questionId), where('type', '==', 'wsq'), where('interview_id', '==', interview_id));
      const querySnapshot = await getDocs(docRef);
      if (!querySnapshot.empty) {
        const docRef = doc(db, 'responses', querySnapshot.docs[0].id);
        await deleteDoc(docRef);
      }
    } catch (error) {
      console.log(error)
    }
    try {
      await addDoc(collection(db, 'responses'), currentResponse)
    }
    catch (error) {
      console.log(error);
    }
    finally {
      navigate(currentResponse.id === 2 ? '/keymotivators' : `/wordquestion/:${questionId + 1}`)
      setCurrentResponse({ type: 'wsq', 
                          id: questionId, 
                          selected: [], 
                          interview_id: interview_id});
    }
  }

  return (
    <div className='h-screen bg-gray-100'>
      <div className='flex w-[8%] items-center space-x-2 p-4 hover:opacity-50'>
          <AiOutlineArrowLeft />
          <Link to='/home'>
            <h1 className='m-0 font-semibold'>Home</h1>  
          </Link>
      </div>
      <div className='items-center justify-center'>
        <div className='mb-10'>
          <h1 className='font-bold text-2xl text-gray-800/50 text-center mb-4'>When it comes to your team...</h1>
          <h1 className='font-bold text-3xl px-4 text-center'>{wordsData[questionId].question}</h1>
        </div>
        <div className='w-[50%] mx-auto grid grid-cols-3 gap-x-6 gap-2 justify-center'>
          {wordsData[questionId].words.sort().map((word, index)  => ( 
            <div key={index} onClick={() => handleSelection(word)} 
              className={`${currentResponse.selected.includes(word) ? 'bg-cyan-200' : 'bg-gray-200 hover:bg-gray-300'} cursor-pointer p-4 rounded-md font-semibold`}>
              {word}
            </div>
          ))}
        </div>

        <div className='flex flex-col'> 
          <div className='flex items-center gap-x-8 justify-center'>
          <Link to={ questionId === 0 ? '/info' : `/wordquestion/:${questionId - 1}`}>
            <button onClick={goToPrevQuestion} className='px-6 py-2 mt-14 ml-24 border-2 bg-black hover:bg-black/70 text-white rounded-lg font-bold'>←</button>
          </Link>
            <button onClick={goToNextQuestion} className='px-6 py-2 mt-14 mr-24 border-2 bg-black hover:bg-black/70 text-white rounded-lg font-bold'>→</button>
          </div>
          <div className='flex justify-end'>
            <h1 className={questionId === 0 ? 'font-bold mt-16 p-10' : questionId === 1 ? 'font-bold mt-16 px-10' : questionId === 2 ? 'font-bold px-10 bottom-10 -my-8' : null}>{questionId + 1}</h1>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WordSelectionQuestion