import React, { useRef } from 'react'
import { BsFillArrowLeftSquareFill } from 'react-icons/bs'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

const ScorecardResponse = ({ rating, disableFunction }) => {

  const pdfRef = useRef()

  const downloadPDF = () => {
    const input = pdfRef.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('l', 'mm', 'a4', false);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 30;
      pdf.addImage(imgData, 'PNG', imgX, imgY, pdfWidth, pdfHeight * 0.6);
      pdf.save(`${rating.company}_${rating.employee}_rating.pdf`);
    });
  }

  return (
    <div className='flex flex-col h-screen bg-gray-100'>
        <div className='flex w-full flex-row justify-between items-center mt-5'>
          <button onClick={disableFunction} className='px-8 text-xl'><BsFillArrowLeftSquareFill /></button>
          <button onClick={downloadPDF} className='p-2 mr-8 bg-blue-600 text-white text-sm font-bold rounded-md hover:bg-blue-500'>Save as PDF</button>
        </div>

        <div ref={pdfRef} className='flex flex-col items-center justify-center'>
          <div className='flex flex-row text-sm justify-center mb-4'>
            <h1 className='p-2'><span className='font-bold'>Company:</span> {rating.company}</h1>
            <h1 className='p-2'><span className='font-bold'>Manager:</span> {rating.manager}</h1>
            <h1 className='p-2'><span className='font-bold'>Employee:</span> {rating.employee}</h1>
          </div>
            <div className='flex-col grid grid-cols-2'>
              {rating.response.map((r, index) => (
                <div key={index} className='mt-4 ml-12 p-2 mr-5 mb-2 text-slate-800'>
                  <div className='p-1 font-medium'><span className='font-bold'>{r.category}: </span>{r.rating.key} ({r.rating.number})</div>
                  <div className='p-1 text-sm'>Notes: {r.notes}</div>
                </div>
              ))
              }
            </div>
          </div>
        </div>
  )
}

export default ScorecardResponse