import React, { useState, useEffect, useRef } from 'react'
import { getFirestore, query, where, collection, getDoc, doc, getDocs } from 'firebase/firestore'
import SummaryPageGroup from './SummaryPageGroup'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

const Summary = () => {

  const interview_id = localStorage.getItem('interview_id')
  const [WSQResponses, setWSQResponses] = useState([])
  const [ASQResponses, setASQResponses] = useState([])
  const [info, setInfo] = useState({companyName: '', contactName: '', position: ''})
  const pdfRef = useRef()

  const downloadPDF = () => {
    const input = pdfRef.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('l', 'mm', 'a4', false);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 30;
      pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
      pdf.save(`${info.contactName}-summary.pdf`);
    });
  }

  useEffect(() => {
    const getResponses = async () => {
      const db = getFirestore();
      try {
        const q = query(collection(db, 'responses'), where('interview_id', '==', interview_id));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const documents = querySnapshot.docs
          let docs = []
          for (let i = 0; i < documents.length; i++) {
            docs.push(documents[i].data())
          }
          setWSQResponses(docs.filter((item) => item.type === 'wsq').sort((a, b) => a.id - b.id))
          setASQResponses(docs.filter((item) => item.type === 'asq').sort((a, b) => a.id - b.id))
        }
      }
      catch (error) {
        console.log(error.message);
      }

      try {
        const docRef = doc(db, 'interviews', interview_id)
        const docSnap = await getDoc(docRef);
        if (!docSnap.empty) {
          const document = docSnap.data()
          setInfo({companyName: document.companyName, contactName: document.contactName, position: document.position});
        }
      }
      catch (error) {
        console.log(error.message);
      }

    }
    getResponses();
  }, [interview_id]); 

  return (
    <div className='h-screen bg-gray-100' ref={pdfRef}>
      <div className='flex w-[8%] items-center space-x-2 p-3 hover:opacity-50'>
        <AiOutlineArrowLeft />
        <Link to='/home'>
          <h1 className='font-semibold'>Home</h1>  
        </Link>
      </div>
      <div className='flex p-5 space-y-2 justify-between '>
        <div>
          <InfoLine forWhat='Company Name' value={info.companyName} />
          <InfoLine forWhat='Contact Name' value={info.contactName} />
          <InfoLine forWhat='Position' value={info.position} />
        </div>
        <div className='mr-20'>
          <button onClick={downloadPDF} className='px-3 py-2 bg-black text-white font-bold rounded-md hover:bg-black/60'>Save as PDF</button>
        </div>
      </div>
      <div className="flex justify-between">
        <div className='w-[50%] p-5'>
          <h1 className='font-bold text-3xl mb-2'>Word Selection Responses</h1>
            <div className=''>
              {WSQResponses.map((response, index) => (
                  <div key={response.id} className='mb-2'>
                    <h1 key={index} className='font-bold text-lg'>{response.id === 0 ? 'Team Values' : response.id === 1 ? 'Recreational Interest' : 'Overall Work Style'}</h1>
                    <div className='flex flex-col text-blue-500'>
                     {response.selected.map((word, index) => ( 
                      <h1 key={index} className='mt-1 leading-normal'>{word}</h1>
                     ))}
                    </div>
                  </div>
              ))}
            </div>
        </div>
        <div className='w-[70%] p-5'>
          <h1 className='font-bold text-3xl mb-2'>Avatar Selection Responses</h1>
          <div className='grid grid-cols-2'>
            <SummaryPageGroup array={ASQResponses.slice(0, 6)} title={'Key Motivators'} />
            <SummaryPageGroup array={ASQResponses.slice(6, 12)} title={'Personality Types'} />
            <SummaryPageGroup array={ASQResponses.slice(12, 18)} title={'Preferences'} />
            <SummaryPageGroup array={ASQResponses.slice(18, 24)} title={'Skill Descriptions'} />
          </div>
        </div>
      </div>
    </div>
  )
  
}

const InfoLine = ({ forWhat, value }) => {
  return (
    <div className='flex space-x-1'>
      <h1 className='font-bold'>{forWhat + ':'}</h1>
      <h1>{value}</h1>
    </div>
  );
};


export default Summary