import React from 'react'
import { Link } from 'react-router-dom'

const BreakPage = ({title, paragraph, nextLink, backLink}) => {
  return (
    <div className='flex flex-col justify-center items-center h-screen bg-gray-100'>
        <h1 className='font-bold text-4xl mb-8 '>{title}</h1>
        <p className='font-normal text-xl '>{paragraph}</p>
        <div className='flex items-center gap-x-8 justify-center'>
            <Link to={backLink}>
                <button className='px-6 py-2 mt-14 ml-24 border-2 bg-black hover:bg-black/70 text-white rounded-lg font-bold'>←</button>
            </Link>
            <Link to={nextLink}>
                <button className='px-6 py-2 mt-14 mr-24 border-2 bg-black hover:bg-black/70 text-white rounded-lg font-bold'>→</button>
            </Link>
        </div>
    </div>
  )
}

export default BreakPage