import React, { useEffect, useState } from 'react'
import { FcOk } from 'react-icons/fc';
import { UserAuth } from './AuthContext';
import { getFirestore, query, collection, where, getDocs, orderBy } from 'firebase/firestore';
import ScorecardResponse from './ScorecardResponse';

const MyScorecards = () => {

    const [myScorecardToken, setMyScorecardToken] = useState('')
    const [myScorecardLink, setMyScorecardLink] = useState('')
    const [copyClicked, setCopyClicked] = useState(false)
    const [ratings, setRatings] = useState([])
    const [ratingSelected, setRatingSelected] = useState('')
    const [searchQuery, setSearchQuery] = useState('');
    const { user } = UserAuth();

    const copyLink = () => {
      navigator.clipboard.writeText(myScorecardLink)
      setCopyClicked(true)
    }
    
    useEffect(() => {
        const fetchRatings = async () => {
          const db = getFirestore();

          // Get scorecard token of this user
          try {
            const q = query(collection(db, 'employees'), where('user_id', '==', user.uid));
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty) {
              const documents = querySnapshot.docs;
              let token = documents[0].data()['scorecard_token'];
              setMyScorecardToken(token);
              setMyScorecardLink(`https://hiregenome.com/scorecard/info/${myScorecardToken}`);
            }
          }
          catch (error) {
            console.log(error.message);
          }

          // Get all scorecards of this user
          try {
            const q = query(collection(db, 'scorecards'), where('scorecard_token', '==', myScorecardToken), where('completed_on', '!=', ""), orderBy('completed_on', 'asc'));
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty) {
              const documents = querySnapshot.docs;
              const temp = []
              for (let i = 0; i < documents.length; i++) {
                temp.push(documents[i].data());
              }
              setRatings(temp);
            }
          }
          catch (error) {
            console.log(error.message);
          } 
        }
        fetchRatings();
      }, [user.uid, myScorecardToken, myScorecardLink]);

      const filteredData = searchQuery === '' ? ratings : ratings.filter((item) => item.employee.toLowerCase().includes(searchQuery.toLowerCase()))

      if (ratingSelected) {
        return <ScorecardResponse rating={ratingSelected} disableFunction={() => setRatingSelected(null)} />
      } 
      else {
        return (
        <div className='flex flex-row h-screen justify-center space-x-16 items-center bg-gray-100'>
            <div className='w-[20%]'>
              <h1 className='font-bold mb-2'>My Scorecards</h1>
              {ratings.length === 0 ? 
                <h1 className='text-gray-400'>Scorecards will show up here.</h1> : 
                <div>
                  <input value={searchQuery} placeholder='Search...' onChange={(e) => setSearchQuery(e.target.value)} className='w-full bg-slate-200 p-2 rounded-lg mb-2'></input>
                  {filteredData.length === 0 ? <h1 className='text-slate-500'>No results found.</h1> :  
                  <ul className='p-2 rounded-lg overflow-y-auto max-h-[360px] text-gray-100 bg-blue-500'>
                    {filteredData.map ((rating, index) => (
                    <li onClick={() => setRatingSelected(rating)} key={index} className='cursor-pointer bg-blue-600/40 rounded-lg p-2 flex flex-col items-start mb-2'>
                        <h1><span className='font-bold'>{rating.company}</span> | {rating.employee}</h1>
                        <h1 className='text-[13px]'>Completed: {rating.completed_on}</h1>
                    </li>
                  ))}
                  </ul>
                  }
              </div>
            }
            </div>

          <div className='flex flex-col items-center justify-center h-[40%]'>
              <h1 className='w-[390px] mb-2 font-bold'>Your Scorecard Link: </h1>
                <div className='w-[390px] rounded-lg bg-gray-200 p-2 mb-4 text-sm'>{myScorecardLink}</div>
                  {!copyClicked ? 
                  <button onClick={copyLink} className='w-[390px] text-center p-2 rounded-lg text-gray-100 font-bold text-sm bg-blue-500 hover:bg-blue-600'>Copy Link</button> 
                  :
                  <div className='flex flex-col items-center'>
                    <button className='w-[390px] p-2 rounded-lg text-gray-100 font-bold text-sm bg-blue-800'>Copied to Clipboard</button> 
                    <FcOk className='absolute mt-12 text-green-500' />
                  </div>
                  }
          </div>
        </div>
        )
    }
}

export default MyScorecards